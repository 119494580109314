import {useState} from "react";

export function useFormInput(initial, type = 'text', onChange = undefined) {
  const [value, setValue] = useState(initial);
  const [disabled, setDisabled] = useState(false);
  let input = {
    disabled,
    onChange: (e) => {
      let value = e.target.value;
      if (e.target.type === 'tel') {
        value = value.replace(/[a-zA-Z- .]/g, '')
      }
      type === 'checkbox' ? setValue(e.target.checked) : setValue(value);
      onChange && onChange(e);
    },
    onBlur: (e) => {
      if (e.target.type !=='checkbox' && e.target.type !=='radio' && e.target.type !== 'file') {
        setValue(e.target.value.trim());
      }
    }
  };

  switch (true) {
    case type === 'checkbox':
      input = {
        ...input,
        checked: value,
      }
      break
    case type === 'radio':
      input = {
        ...input,
      }
      break
    default:
      input = {
        ...input,
        value,
      }
  }

  return {
    value,
    setValue,
    setDisabled,
    setDefault: () => setValue(initial),
    input,
  }
}
