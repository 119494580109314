import React, {useEffect, useState} from "react"
import {graphql} from "gatsby"
import Img from "gatsby-image";

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from './mas-informacion.module.css';
import fromStyles from './form.module.css';
import PrimaryButton from "../components/primary-button";
import {getProvinces, registerLead} from "../services/pymes-service";
import {useFormInput} from "../hooks/form-input";
import formStyles from "./form.module.css";

const MoreInfoPage = ({data}) => {
  const province = useFormInput('');
  const company = useFormInput('');
  const firstName = useFormInput('');
  const lastName = useFormInput('');
  const email = useFormInput('');
  const phone = useFormInput('');
  const terms = useFormInput(false, 'checkbox');
  const [provinces, setProvinces] = useState('');

  const inputs = [province, company, firstName, lastName, email, phone, terms];
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);

  const formSending = (value) => {
    setSending(value);
    inputs.forEach(i => i.setDisabled(value));
  };

  useEffect(() => {
    getProvinces().then((items) => {
      const provinceElements = items.map(i => <option key={i.id} value={`${i.code}-${i.name}`}>{i.name}</option>);
      setProvinces(provinceElements);
    });
  }, []);

  const handleSubmit = event => {
    event.preventDefault();
    formSending(true);
    setMessage('Enviando...');

    const contact = {
      provinceId: Number(province.value.slice(0, 2)),
      province: province.value.slice(3),
      company: company.value,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
    };

    registerLead(contact)
      .then(() => {
        setMessage('Hemos recibido tus datos. Pronto nos pondremos en contacto contigo.');
        inputs.forEach(i => i.setDefault());
      })
      .catch(() => setMessage('Error al enviar la información. Vuelva a intentarlo en unos instantes, si el problema persiste, póngase en contacto con nosotros.'))
      .finally(() => formSending(false));
  }

  return (
    <Layout>
      <SEO
        title="Mas información"
        description="Déjanos tus datos y te llamaremos para informarte y ayudarte"
      />
      <Img fluid={[
        data.mobileImage.childImageSharp.fluid,
        {
          ...data.desktopImage.childImageSharp.fluid,
          media: `(min-width: 1440px)`,
        },
        {
          ...data.ipaqImage.childImageSharp.fluid,
          media: `(min-width: 400px)`,
        },
      ]}/>
      <article className={styles.article}>
        <h1>Déjanos tus datos y te llamaremos para informarte y ayudarte</h1>
        <form className={fromStyles.form} onSubmit={handleSubmit}>
          <section>
            <label>
              <span>NOMBRE (*)</span>
              <input required type="text" minLength="3" name="firstName" {...firstName.input} />
            </label>
            <label>
              <span>APELLIDOS (*)</span>
              <input required type="text" minLength="3" name="lastName" {...lastName.input} />
            </label>
            <label>
              <span>CORREO ELECTRÓNICO (*)</span>
              <input required type="email" name="email" {...email.input} />
            </label>
            <label>
              <span>TELÉFONO MÓVIL (*)</span>
              <input required minLength="9" name="phone" maxLength="9" type="tel" {...phone.input} />
            </label>
            <label>
              <span>NOMBRE PYME (*)</span>
              <input required type="text" minLength="3" name="company" {...company.input} />
            </label>
            <label>
              <span>PROVINCIA (*)</span>
              <select required name="province" {...province.input}>
                <option value="">seleccione...</option>
                {provinces}
              </select>
            </label>
            <label>
              <div>
                <input required type="checkbox" {...terms.input} />
                <div>
                  Acepto el <a target="_blank" rel="noreferrer" href="https://www.eldiario.es/aviso_legal/">
                  Aviso Legal
                </a> la <a target="_blank" rel="noreferrer" href="https://www.eldiario.es/privacidad/">
                  Política de privacidad
                </a>
                </div>
              </div>
            </label>
          </section>
          <PrimaryButton type="submit" disabled={sending}>QUIERO MÁS INFORMACIÓN</PrimaryButton>
        </form>
        {
          message ?
            message.startsWith('Error') ?
              <p className={[formStyles.message, formStyles.error].join(' ')}>{message}</p> :
              <p className={[formStyles.message, formStyles.ok].join(' ')}>{message}</p> :
            ''
        }
      </article>
    </Layout>
  );
}

export default MoreInfoPage;

export const query = graphql`
    query {
      mobileImage: file(relativePath: { eq: "man-business-card.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 255, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ipaqImage: file(relativePath: { eq: "man-business-card-ipaq.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "man-business-card-pc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `
